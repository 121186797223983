import { useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { HeightProvider } from 'contexts/PricingPlanHeight';

// Pre-defined Components
import { PRODUCTS_IMAGES as productsImages } from 'config/images/products';

// Sub Components
import PlusAllInOnePlan from './PlusAllInOnePlan';
import PlusEnterprise from './PlusEnterprise';

const ContactMeModal = dynamic(() => import('components/Shared/ContactMe'),{ ssr: false });
const UILoadingOverlay = dynamic(() => import('components/Shared/UILoadingOverlay'), {
    ssr: false,
});

const NotificationToast = dynamic(() =>
    import('123rf-ui-core/components').then((mod) => mod.NotificationToast),
);

function AllInOnePlans(props) {
    const { WindowDimension, User } = props;
    const {
        isDesktopView,
        isTabletView,
        isMobileView,
    } = WindowDimension;
    
    const NOTIFICATION_TYPES = {
        SUCCESS: 'success',
        WARNING: 'warning',
        ERROR: 'error',
    };

    const NOTIFICATION_MESSAGES = {
        RATE_LIMITED: 'SHARED.FormSubmitRateLimited',
        CAPTCHA_EXPIRED: 'SHARED.FormSubmitCaptchaExpired',
        GENERAL_ERROR: 'SHARED.FormSubmitErrMsg',
        SUCCESS: 'PRODUCTS.RequestFormSubmitSuccess',
    };

    const HTTP_STATUS = {
        TOO_MANY_REQUESTS: 429,
        BAD_REQUEST: 400,
    }

    const [showContactMeModal, setShowContactMeModal] = useState(false);

    const [notification, setNotification] = useState({ type: NOTIFICATION_TYPES.SUCCESS, text: '' });
    const [showNotification, setShowNotification] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleNotification = (response) => {
        let type = NOTIFICATION_TYPES.SUCCESS;
        let messageKey = NOTIFICATION_MESSAGES.SUCCESS;

        if (response?.data?.error) {
            if (response.status === HTTP_STATUS.TOO_MANY_REQUESTS) {
                type = NOTIFICATION_TYPES.WARNING;
                messageKey = NOTIFICATION_MESSAGES.RATE_LIMITED;
            } else if (response.status === HTTP_STATUS.BAD_REQUEST) {
                type = NOTIFICATION_TYPES.WARNING;
                messageKey = NOTIFICATION_MESSAGES.CAPTCHA_EXPIRED;
            } else {
                type = NOTIFICATION_TYPES.ERROR;
                messageKey = NOTIFICATION_MESSAGES.GENERAL_ERROR;
            }
        }

        setNotification({
            type,
            text: intl.get(messageKey),
        });
        setShowNotification(true);
    };

    return (
        <HeightProvider>
            <section className="AllInOnePlans__wrapper">
                <h1 className="AllInOnePlans__title">
                    {intl.getHTML('PRODUCTS.ChoosePlan_Title')}
                </h1>

                <div className="AllInOnePlans__subWrapper">
                    <PlusAllInOnePlan 
                        {...props}
                    />

                    <PlusEnterprise
                        {...props}
                        onTalkToUs={() => setShowContactMeModal(true)}
                    />
                </div>

                {showContactMeModal && (
                    <ContactMeModal
                        showContactMeModal={showContactMeModal}
                        setShowContactMeModal={setShowContactMeModal}
                        showCloseButton
                        isDesktopView={isDesktopView}
                        isTabletView={isTabletView}
                        isMobileView={isMobileView}
                        UserInfo={User}
                        onNotification={handleNotification}
                        setLoading={setLoading}
                        showPreferredContactMethod
                        inquiryType='plus-enterprise'
                    />
                )}
                {loading && <UILoadingOverlay />}

                {showNotification && (
                    <NotificationToast
                        id="new-affordable-plan-toast"
                        className="AllInOnePlans__toast"
                        type={notification.type}
                        text={notification.text}
                        onHidden={() => setShowNotification(false)}
                        closeBtn
                    />
                )}

                <style jsx>
                    {`
                        .AllInOnePlans {
                            &__wrapper {
                                gap: 16px;
                                // @include breakpoint('md') {
                                @media (min-width: 768px) {
                                    gap: 24px;
                                }
                            }

                            &__title {
                                width: 100%;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 1.5rem;
                                text-align: center;
                                color: var(--Greyscale-G5, #1A1A1A);
                                z-index: 1;

                                // @include breakpoint('md') {
                                @media (min-width: 768px) {
                                    font-size: 24px;
                                    line-height: 2.25rem;
                                }
                            }

                            &__subWrapper {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 16px;
                                
                                // @include breakpoint('md') {
                                @media (min-width: 768px) {
                                    flex-direction: row;
                                    gap: 24px;
                                }

                                // @include breakpoint('lg') {
                                @media (min-width: 1024px) {
                                    gap: 32px;
                                }
                            }

                            &__contactMe {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 1.25rem;
                                color: #ffffff;
                                z-index: 1;
                                margin-top: 16px;

                                &--text {
                                    text-align: center;
                                }

                                // @include breakpoint('md') {
                                @media (min-width: 768px) {
                                    margin-top: 20px;
                                }

                                // @include breakpoint('lg') {
                                @media (min-width: 1024px) {
                                    margin-top: 24.5px;
                                }
                            }


                        }
                    `}
                </style>
                <style jsx global>
                    {`
                        .AllInOnePlans {
                            &__wrapper {
                                position: relative;
                                overflow: visible;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), url(${productsImages.allInOnePricingBG}) lightgray -11.271px -430.831px / 472.122% 167.493% no-repeat;
                                padding: 16px;

                                // @include breakpoint('md') {
                                @media (min-width: 768px) {
                                    padding: 40px 32px;
                                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), url(${productsImages.allInOnePricingBG}) lightgray 0px -506.831px / 100% 259.928% no-repeat;
                                }

                                // @include breakpoint('xl') {
                                @media (min-width: 1200px) {
                                    padding:50px 150px;
                                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), url(${productsImages.allInOnePricingBG}) lightgray 0px -556.781px / 100% 259.928% no-repeat;
                                }
                            }

                            &__commonLink {
                                color: #2298ca !important;
                                text-underline-offset: 1px !important;
                                text-decoration-line: underline !important;
                                -webkit-text-decoration-line: underline !important;
                                text-decoration-thickness: 1px !important;
                                text-underline-position: under !important;

                                &:hover {
                                    color: #e0e0e0 !important;
                                    cursor: pointer;
                                }
                            }

                            &__toast {
                                position: fixed;
                                width: calc(100% - 20px) !important;
                                max-width: 510px !important;
                                top: 10%;
                                left: 50%;
                                transform: translate(-50%);
                                z-index: 9999;
                            }
                        }
                    `}
                </style>
            </section>
        </HeightProvider>
    );
}

AllInOnePlans.propTypes = {
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    User: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default AllInOnePlans;

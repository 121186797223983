import cx from 'classnames';
import PropTypes from 'prop-types';

// Pre-defined Components
import { PLAN_TYPE_ANNUALLY, PLAN_TYPE_ANNUALLY_INSTALLMENT, PLAN_TYPES } from 'config/products';

import { RadioButton } from '123rf-ui-core/components';

// Sub Components
import PlanParticulars from './PlanParticulars';
import PlanPricing from './PlanPricing';

const PlanOptionItem = (props) => {
    const {
        cardType,
        data,
        selectedPlan,
        selectedCredits,
        handleOptionSelection,
        WindowDimension,
        isAllInOnePlanOn,
    } = props;
    const { width: winWidth } = WindowDimension;
    const isMobileMaxWidth340 = winWidth <= 340;

    const isYearlyPlan =
        PLAN_TYPE_ANNUALLY.includes(selectedPlan) ||
        PLAN_TYPE_ANNUALLY_INSTALLMENT.includes(selectedPlan);
    const plan = data?.plan || '';
    const quota = data?.quota || 0;
    const credits = data?.credits || 0;
    const isSelected =
        cardType === PLAN_TYPES.SUBSCRIPTION || cardType === PLAN_TYPES.PLUS
            ? selectedPlan === plan
            : selectedCredits === credits;
    const optionIdValue =
        cardType === PLAN_TYPES.SUBSCRIPTION || cardType === PLAN_TYPES.PLUS ? plan : credits;

    const PlanDetails = () => (
        <div className="PlanOptionItem__particularsWrapper">
            <PlanParticulars
                cardType={cardType}
                credits={credits}
                quota={quota}
                isYearlyPlan={isYearlyPlan}
                {...props}
            />
            <PlanPricing
                cardType={cardType}
                credits={credits}
                isYearlyPlan={isYearlyPlan}
                {...props}
            />
        </div>
    );

    return (
        <div
            className={cx(
                'PlanOptionItem__wrapper',
                isSelected && 'PlanOptionItem__wrapper--selected',
            )}
            role="radio"
            tabIndex="0"
            onClick={() => handleOptionSelection(optionIdValue)}
            onKeyDown={(e) => e.key === 'Enter' && handleOptionSelection(optionIdValue)}
            data-testid={optionIdValue}
            data-value={optionIdValue}
            aria-checked={isSelected}
        >
            <div className="PlanOptionItem__radioButtonWrapper">
                <RadioButton
                    id={optionIdValue?.toString()}
                    value={optionIdValue?.toString()}
                    checked={isSelected}
                    onChange={() => {}}
                    label={<PlanDetails />}
                    className="w-100"
                />
            </div>

            <style jsx global>
                {`
                    .PlanOptionItem {
                        &__wrapper {
                            position: relative;
                            width: 100%;
                            min-height: ${isAllInOnePlanOn ? '60px' : '65px'};
                            display: flex;
                            align-items: center;
                            padding: ${isAllInOnePlanOn ? '16px' : '9px 10px'};
                            gap: ${isMobileMaxWidth340 ? '12px' : '16px'};
                            background: #ffffff;
                            border: 1px solid #e0e0e0;
                            border-radius: 8px;
                            box-sizing: border-box;
                            cursor: pointer;
                            -webkit-transition: all 0.3s ease-out;
                            -moz-transition: all 0.3s ease-out;
                            -o-transition: all 0.3s ease-out;
                            transition: all 0.3s ease-out;

                            &:hover {
                                background: #f9f9f9;
                                border: 1px solid #e0e0e0;
                            }

                            &--selected,
                            &--selected:hover {
                                background: #fff6dc;
                                border: 1px solid #f0ba27;
                                cursor: default;
                            }

                            .Radio__container {
                                width: 100%;
                            }

                            .Radio__label {
                                width: 100%;
                            }
                        }

                        &__radioButtonWrapper {
                            position: relative;
                            width: 100%;
                        }

                        &__particularsWrapper {
                            display: grid;
                            grid-template-columns: auto auto;
                            column-gap: 8px;
                            align-items: center;
                            margin-left: -6px;
                        }
                    }
                `}
            </style>
        </div>
    );
};

PlanOptionItem.propTypes = {
    cardType: PropTypes.string,
    data: PropTypes.objectOf(PropTypes.shape).isRequired,
    selectedPlan: PropTypes.string,
    selectedCredits: PropTypes.number,
    handleOptionSelection: PropTypes.func.isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    isAllInOnePlanOn: PropTypes.bool,
};

PlanOptionItem.defaultProps = {
    cardType: '',
    selectedPlan: '',
    selectedCredits: 0,
    isAllInOnePlanOn: false,
};

export default PlanOptionItem;

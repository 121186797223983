// Products-initiated Links
export const CORPORATE_URL = '/corporate/';
export const FAQ_REFUND_URL = '/faq.php#refund';
// export const PIXLR_URL = 'https://pixlr.com';
// export const PIXLR_UTM_SRC_PATH =
//     '?utm_source=123RF&utm_medium=Pricing-Page&utm_campaign=CrossSell';
// export const DESIGNS_AI_URL = 'https://designs.ai';
// export const DESIGNS_AI_UTM_SRC_PATH =
//     '?utm_source=123RF&utm_medium=Pricing-Page&utm_campaign=CrossSell';

// Active Plans and Credits
export const SUBS_PLAN_NAMES_ALL =
    'demi1,tall1,regular1,grande1,demi12,tall12,regular12,grande12,i-demi12,i-tall12,i-regular12,i-grande12,plus1,splus12,fplus7,plusw1,plusb1,plusb12,i-splus12';
export const SUBS_PLAN_NAMES =
    'demi1,tall1,regular1,grande1,i-demi12,i-tall12,i-regular12,i-grande12,plus1,splus12,fplus7,plusb1,plusb12,plusw1,i-splus12';
export const SUBS_PLAN_NAMES_REGULAR =
    'demi1,tall1,regular1,grande1,demi12,tall12,regular12,grande12,plus1,splus12,fplus7,plusb1,plusb12,plusw1,';
// export const CREDITS_LIST = '30,90,270,600';
export const CREDITS_LIST = '50,100,600';
// export const PSUB_PLAN_NAMES = 'psubdp3,psubdp10,psubdp25,psubdp100';

// Valid Monthly Plan Types
export const PLAN_TYPE_MONTHLY = ['demi1', 'tall1', 'regular1', 'grande1'];
export const PLAN_TYPE_PLUS = ['splus12', 'fplus7', 'plus1', 'plusw1','i-splus12'];
export const PLAN_TYPE_PLUS_BUSINESS = ['plusb1', 'plusb12'];

// Valid Monthly Plan Types with Monthly Payments
// export const PLAN_TYPE_MONTHLY_INSTALLMENT = ['i-demi1', 'i-tall1', 'i-regular1', 'i-grande1'];

// Valid Yearly Plan Types
export const PLAN_TYPE_ANNUALLY = ['demi12', 'tall12', 'regular12', 'grande12'];

// Valid Yearly Plan Types with Monthly Payments
export const PLAN_TYPE_ANNUALLY_INSTALLMENT = ['i-demi12', 'i-tall12', 'i-regular12', 'i-grande12','i-splus12'];

export const DEFAULT_CREDITS = {
    selectedAIO: 50,
    selected: 90,
    minCustom: 10,
    minCustomAIO: 10,
    maxCustom: 1000,
    defaultCustom: 600,
    autoFallbackAIO: 20,
};

// Valid Download Pack Plan Types
// export const DOWNLOAD_PACK_PLANS = ['psubdp5', 'psubdp10', 'psubdp25', 'psubdp100'];

// Types of Plan Duration
export const PLAN_DURATION = { yearly: 12, monthly: 1 };

// Display Nea A Customized Plan section for these languages
// export const CUSTOMIZED_PLAN_LANG = ['en', 'es', 'fr', 'it', 'pt', 'jp', 'tw'];

// Countries with option to pay in Local Currency
export const PAY_IN_LOCAL_CURRENCY = ['TH', 'MX', 'TR', 'HU', 'RS', 'BG', 'RO'];

// Countries with Tax
export const COUNTRIES_WITH_TAX = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'FX',
    'GB',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
    'KR',
];

export const CREDITS_ONLY_PAGE_ORIGIN = ['footage', 'cic', 'packs', 'video', 'audio'];

// Countries without Installment Plans, US, CA, AU, NZ added to list for afterPay, GB Added for clearPay
export const COUNTRIES_WITHOUT_INSTALLMENT = [
    'KR',
    'RU',
    'IN',
    // 'US',
    // 'CA',
    // 'AU',
    // 'NZ', we are not gonna support afterpay for NZ. confirmation from jackson
    // 'GB',
];

// Countries without Plus Plans - Pricing Page
export const COUNTRIES_WITHOUT_PLUS = [
    // 'AU',
    // 'AT',
    // 'BE',
    // 'TW',
    // 'HK',
    // 'HU',
    // 'IE',
    // 'IL',
    // 'IT',
    // 'JP',
    // 'KR',
    // 'MY',
    // 'PL',
    // 'PT',
    // 'RO',
    // 'SG',
    // 'ES',
    // 'SE',
    // 'CH',
    // 'GB',
    // 'US',
    // 'CN',
    // 'CZ',
    // 'FR',
    // 'DE',
];

// Countries without Autorenewal
export const COUNTRIES_WITHOUT_AUTORENEWAL = ['KR', 'RU','IN'];

// Countries without Plus Free Trial
export const COUNTRIES_WITHOUT_PLUS_FREE_TRIAL = [
    'CZ',
    'GR',
    'NO',
    'SE',
    'DK',
    'IS',
    'IN',
    'BD',
    'PK',
    'PH',
    'BR',
    'IN',
    'MX',
    'CO',
    'ID',
    'AR',
    'ZA',
    'TW',
    'AT',
    'IE',
];

// Types of Plans
export const PLAN_TYPES = {
    CREDIT: 'credits',
    PLUS: 'plus',
    PLUS_BUSINESS: 'plus_business',
    SUBSCRIPTION: 'subs',
    ENTERPRISE: 'enterprise',
};

export const PREMIUM_ONLY_PAGE_ORIGIN = ['premium'];

export const PLANS_WITH_PROMOCODE_PRICING = [
    { plan: 'fplus7', promocode: 'freeplus' },
];

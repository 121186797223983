import PropTypes from 'prop-types';

const PlusEnterprisePlanDetailsItem = ({ item }) => (
    <li className="PlanDetails__list">
        <span
            className="PlanDetails__listItem"
        >
            {item}
        </span>

        <style jsx>
            {`
                .PlanDetails {
                    &__tickIcon {
                        height: 24px;
                        width: 25px;
                    }

                    &__listItem {
                        display: flex;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 1.125rem;
                        color: #212121;
                    }
                }
            `}
        </style>
        <style jsx global>
            {`
                .PlanDetails {
                    &__link {
                        a {
                            color: #2298ca !important;
                            text-decoration-line: underline !important;
                            text-underline-offset: 4px;
                        }
                    }
                }
            `}
        </style>
    </li>
);

PlusEnterprisePlanDetailsItem.propTypes = {
    item: PropTypes.node.isRequired,
};

export default PlusEnterprisePlanDetailsItem;

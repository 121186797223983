import React, { createContext, useContext, useState, useRef, useEffect, useMemo, startTransition } from 'react';
import { WindowDimensionContext } from 'contexts/WindowDimension';
import PropTypes from 'prop-types';

const HeightContext = createContext();

export const HeightProvider = ({ children }) => {
  const WindowDimension = useContext(WindowDimensionContext);
  const { width: windowWidth } = WindowDimension;

  const [plusPlanHeight, setPlusPlanHeight] = useState('215px');
  const [subsPlanHeight, setSubsPlanHeight] = useState('370px');
  const [aioPlanHeight, setAioPlanHeight] = useState(null);
  const [enterpriseHeaderHeight, setEnterpriseHeaderHeight] = useState('122px');

  const plusPlanRef = useRef(null);
  const subsPlanRef = useRef(null);
  const enterprisePlanRef = useRef(null);
  const plusAIOPlanRef = useRef(null);
  const aioHeaderHeightRef = useRef(null);

  useEffect(() => {
    // reset after windowWidth changed
    setAioPlanHeight(null);
  },[windowWidth]);

  const updateHeight = (ref, setFunction, ref2) => {
    if (ref.current) {
      const heightWithoutMarginRef1 = ref.current.offsetHeight;
  
      // Get the height of ref2 if it exists
      const heightWithoutMarginRef2 = ref2?.current ? ref2.current.offsetHeight : 0;
  
      // Determine the tallest height between ref1 and ref2
      const tallestHeight = Math.max(heightWithoutMarginRef1, heightWithoutMarginRef2);
      startTransition(() => {
        setFunction(`${tallestHeight}px`);
      });
    }
  };

  useEffect(() => {
    updateHeight(plusPlanRef, setPlusPlanHeight);
    updateHeight(subsPlanRef, setSubsPlanHeight);

    const observer1 = new ResizeObserver(() => updateHeight(plusPlanRef, setPlusPlanHeight));
    const observer2 = new ResizeObserver(() => updateHeight(subsPlanRef, setSubsPlanHeight));

    if (plusPlanRef.current) observer1.observe(plusPlanRef.current);
    if (subsPlanRef.current) observer2.observe(subsPlanRef.current);

    // Cleanup the observers on unmount
    return () => {
      if (plusPlanRef.current) observer1.unobserve(plusPlanRef.current);
      if (subsPlanRef.current) observer2.unobserve(subsPlanRef.current);
    };
  }, []);

  useEffect(() => {
    // Create a ResizeObserver instance
    const resizeObserver = new ResizeObserver(() => {
      // Update heights whenever observed elements are resized
      updateHeight(enterprisePlanRef, setAioPlanHeight, plusAIOPlanRef);
      updateHeight(aioHeaderHeightRef, setEnterpriseHeaderHeight);
    });

    // Observe both refs
    if (enterprisePlanRef.current) resizeObserver.observe(enterprisePlanRef.current);
    if (plusAIOPlanRef.current) resizeObserver.observe(plusAIOPlanRef.current);
    if (aioHeaderHeightRef.current) resizeObserver.observe(aioHeaderHeightRef.current);

    // Cleanup: Unobserve elements when component unmounts
    return () => {
      if (enterprisePlanRef.current) resizeObserver.unobserve(enterprisePlanRef.current);
      if (plusAIOPlanRef.current) resizeObserver.unobserve(plusAIOPlanRef.current);
      if (aioHeaderHeightRef.current) resizeObserver.unobserve(aioHeaderHeightRef.current);
    };
  },[enterprisePlanRef.current, plusAIOPlanRef.current, aioHeaderHeightRef.current, updateHeight]);

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(() => ({
    plusPlanHeight,
    subsPlanHeight,
    aioPlanHeight,
    aioHeaderHeightRef,
    plusPlanRef,
    subsPlanRef,
    enterprisePlanRef,
    plusAIOPlanRef,
    enterpriseHeaderHeight,
    setAioPlanHeight,
    setEnterpriseHeaderHeight,
  }), [plusPlanHeight, subsPlanHeight, aioPlanHeight]);

  return (
    <HeightContext.Provider value={contextValue}>
      {children}
    </HeightContext.Provider>
  );
};

export const useHeightContext = () => {
  return useContext(HeightContext);
};

HeightProvider.propTypes = {
  children: PropTypes.node.isRequired,
};


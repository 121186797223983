import intl from 'react-intl-universal';

// Sub Component
import PlusEnterprisePlanDetailsItem from './PlusEnterprisePlanDetailsItem';

const PlusEnterprisePlanDetails = () => {
    return (
      <section className="PlusEnterprisePlanDetails__wrapper">
        <div className="PlusEnterprisePlanDetails__title">
          {intl.get('PRODUCTS.ComparisonTable_EverythingInPlus')}
        </div>
        <ul className="PlusEnterprisePlanDetails__list">
            <PlusEnterprisePlanDetailsItem item={intl.get('PRODUCTS.ComparisonTable_MultiUserAccess')} />
            <PlusEnterprisePlanDetailsItem item={intl.getHTML('PRODUCTS.ComparisonTable_MultiProjectUsage')} />
            <PlusEnterprisePlanDetailsItem item={intl.getHTML('PRODUCTS.ComparisonTable_CustomLicensing')} />
            <PlusEnterprisePlanDetailsItem item={intl.getHTML('PRODUCTS.ComparisonTable_TailoredIndemnity')} />
            <PlusEnterprisePlanDetailsItem item={intl.getHTML('PRODUCTS.ComparisonTable_DedicatedAccountManager')} />
        </ul>

        <style jsx global>
          {`
              .PlusEnterprisePlanDetails {
                &__title {
                  color: var(--Greyscale-G10, #212121);
                  /* Desktop/Body/B 1 Semibold */
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px; /* 150% */
                }

                &__wrapper {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }

                &__list {
                  width: 100%;
                  height: auto;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  align-self: stretch;
                  margin-bottom: unset !important;
                  padding-left: 20px !important;
                  gap: 8px;

                  color: var(--Greyscale-G10, #212121);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 150% */

                  > ul {
                    list-style: none;
                    padding-inline-start: 0px;
                    margin-bottom: 0rem;
                  }

                  > li {
                    display: flex;
                    align-items: center;
                    margin-left: 0;
                    padding-left: 0;
                    position: relative;
                    list-style-position: inside;

                    &::before {
                      content: '•';
                      position: absolute;
                      left: -13px;
                      top: 0;
                    }
                  }
                }
              }
          `}
        </style>
      </section>
    );
};

export default PlusEnterprisePlanDetails;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

// Dynamic Component
import { AISuiteTooltips as UICoreAISuiteTooltips } from '123rf-ui-core/components';

function AccessToAISuite (props) {
    const { isFullAccess = false } = props;

    const [hydrated, setHydrated] = useState(false);

    useEffect(() => {
        setHydrated(true);
    }, []);

    if (!hydrated) {
        // Show a placeholder or default UI during hydration
        return null; 
    }

    return ( 
    <div className="PlanDetails__container--tooltip">
        <span>{intl.get(isFullAccess ? 'PRODUCTS.FullAccessToAISuite': 'PRODUCTS.PlusAccessToAISuite')}</span>
        <UICoreAISuiteTooltips />

        <style jsx global>
        {`
            .PlanDetails {
                &__container {
                    &--tooltip {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        // @include breakpoint('md') {
                        @media (min-width: 768px) {
                            gap: unset;
                        }

                        > .AISuiteTooltip {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 18px;
                            > img {
                                height: 16px !important;
                            }
                        }
                    }

                    &--innerTooltip {
                        min-width: 200px;
                        max-width: 300px !important;

                        padding: 8px !important;
                        border-radius: 8px !important;
                    }

                    &--tooltipImage {
                        cursor: pointer;
                    }
                }

                &__tooltip {
                    &--container {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }

                    &--header {
                        color: #212121;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.25rem;
                    }

                    &--list {
                        margin-bottom: 0;

                        & > li {
                            color: #212121;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.25rem;
                        }
                    }
                }
            }
        `}
        </style>
    </div>
    );
};

AccessToAISuite.propTypes = {
    isFullAccess: PropTypes.bool
};

AccessToAISuite.defaultProps = {
    isFullAccess: false,
}

export default AccessToAISuite;

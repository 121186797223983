import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Router, { useRouter } from 'next/router';

// Pre-defined Components
import { DEFAULT_CREDITS, PLAN_TYPES } from 'config/products';
import { PAGE_FOLDERS, DEFAULT_STATE_TEXT_VALUE as stateDefault } from 'config/constants';
import { PAYMENT_METHOD, TAZAPAY_ACCEPTED_COUNTRIES } from 'config/payments';

// API
import getCustomCredits from 'api/queries/getCustomCredits';

import { Button, Checkbox } from '123rf-ui-core/components';

// helper
import getPageType from 'helpers/client_side/getPageType';
import getContentGroup from 'helpers/client_side/getContentGroup';

// Sub Components
import PlanOptionItem from 'components/Products/NewAffordablePlans/Shared/PlanOptionItem/index';
import CreditPackDetails from './CreditPackDetails';

const CreditPackV2 = (props) => {
    const {
        products,
        CommonDetails,
        GTM,
        WindowDimension,
        refCreditPacksCanBuy,
        isAllInOnePlanOn,
    } = props;

    const { countryOrigin: co, locale, pageOrigin, promoCode, utmCampaign, lang } = CommonDetails;
    const { isMobileView, isTabletView } = WindowDimension;
    const countryOrigin = locale?.country?.code || co;

    let creditPackOptions = products?.credits || [];

    // Re-arrange credits options, custom credits must be placed at the bottom
    const nonCustomCreditsOptions = creditPackOptions.filter(
        (d) => d.credits !== DEFAULT_CREDITS.defaultCustom,
    );
    const customCreditsOption = creditPackOptions.filter(
        (d) => d.credits === DEFAULT_CREDITS.defaultCustom,
    );
    creditPackOptions = [...nonCustomCreditsOptions, ...customCreditsOption];

    const DEFAULT_CREDITS_SELECTED = isAllInOnePlanOn ? DEFAULT_CREDITS.selectedAIO : DEFAULT_CREDITS.selected;
    const DEFAULT_CREDITS_MIN_CUSTOM = isAllInOnePlanOn ? DEFAULT_CREDITS.minCustomAIO : DEFAULT_CREDITS.minCustom;

    // Local States
    const [showCustomCredits, setShowCustomCredits] = useState(false);
    const [selectedCredits, setSelectedCredits] = useState(DEFAULT_CREDITS_SELECTED);
    const [customCredits, setCustomCredits] = useState(DEFAULT_CREDITS.defaultCustom);
    const [customCreditsNote, setCustomCreditsNote] = useState('');
    const [customCreditsDetails, setCustomCreditsDetails] = useState();
    const [autorenewChecked, setAutorenewChecked] = useState(
        countryOrigin !== 'RU' && countryOrigin !== 'KR' && !TAZAPAY_ACCEPTED_COUNTRIES.includes(countryOrigin),
    );

    const [buyNowClicked, setBuyNowClicked] = useState(undefined);

    const { pathname } = useRouter();
    const isLangKR = lang === 'kr';

    const handlePricingButtonClick = () => {
        return setBuyNowClicked((prev) => !prev);
    };

    const handleOptionSelection = (selected) => {
        if (selectedCredits !== selected) {
            setSelectedCredits(selected);
            setShowCustomCredits(selected === DEFAULT_CREDITS.defaultCustom);

            const creditsPrice =
                selected === DEFAULT_CREDITS.defaultCustom
                    ? customCreditsDetails?.credits?.price?.rounded_price || 0
                    : creditPackOptions.filter((o) => o.credits === selected)[0]?.price
                          ?.rounded_price || 0;

            GTM.pushGTMData({
                event: 'ecommerce_detail',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name: `${
                                    selected === DEFAULT_CREDITS.defaultCustom ? 'Custom' : selected
                                } Download Credits`,
                                id: `${
                                    selected === DEFAULT_CREDITS.defaultCustom ? 'Custom' : selected
                                }`,
                                price: creditsPrice,
                                currency: locale.currency.code,
                                brand: 'Online',
                                category: 'Credits',
                                variant:
                                    selected === DEFAULT_CREDITS.defaultCustom
                                        ? 'Custom Credits'
                                        : '',
                                quantity: 1,
                                metric1:
                                    selected === DEFAULT_CREDITS.defaultCustom
                                        ? parseInt(customCredits, 10)
                                        : selected,
                                metric2: 0,
                                metric3: 0,
                            },
                        ],
                    },
                },
            });
        }
    };

    const processCustomCredits = (event) => {
        const sanitizedValue = event.target.value.replace(/^0+/, ''); // Remove leading zeros
        setCustomCredits(sanitizedValue);
    };

    const retrieveCustomCredits = async (signal) => {
        let parsedCustomCredits = parseInt(customCredits || 0, 10);
        if (parsedCustomCredits > DEFAULT_CREDITS.maxCustom) {
            parsedCustomCredits = DEFAULT_CREDITS.maxCustom;
            setCustomCredits(parsedCustomCredits);
            setCustomCreditsNote(
                intl.get('PRODUCTS.ChoosePlan_MaximumOfErrMsg', {
                    counts: DEFAULT_CREDITS.maxCustom,
                }),
            );
            return;
        }
        if (parsedCustomCredits < DEFAULT_CREDITS_MIN_CUSTOM) {
            parsedCustomCredits = isAllInOnePlanOn 
                ? DEFAULT_CREDITS.autoFallbackAIO 
                : DEFAULT_CREDITS_MIN_CUSTOM;
            setCustomCredits(parsedCustomCredits);
            return;
        }

        if (customCreditsDetails?.credits?.credits !== parsedCustomCredits) {
            // Call Custom Credits API
            const creditsResponse = await getCustomCredits(
                {},
                countryOrigin,
                parsedCustomCredits || DEFAULT_CREDITS.defaultCustom,
                signal,
            );
            creditsResponse && setCustomCreditsDetails(creditsResponse);
            !parsedCustomCredits && setCustomCredits(DEFAULT_CREDITS.defaultCustom);

            const creditsPrice =
                selectedCredits === DEFAULT_CREDITS.defaultCustom
                    ? creditsResponse?.credits?.price?.rounded_price || 0
                    : creditPackOptions.filter((o) => o.credits === selectedCredits)[0]?.price
                          ?.rounded_price || 0;

            GTM.pushGTMData({
                event: 'ecommerce_detail',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name: `${
                                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                                        ? 'Custom'
                                        : selectedCredits
                                } Download Credits`,
                                id: `${
                                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                                        ? 'Custom'
                                        : selectedCredits
                                }`,
                                price: creditsPrice,
                                currency: locale.currency.code,
                                brand: 'Online',
                                category: 'Credits',
                                variant:
                                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                                        ? 'Custom Credits'
                                        : '',
                                quantity: 1,
                                metric1:
                                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                                        ? parseInt(customCredits, 10)
                                        : selectedCredits,
                                metric2: 0,
                                metric3: 0,
                            },
                        ],
                    },
                },
            });
        }

        setCustomCreditsNote('');
    };

    useEffect(() => {
        if (pageOrigin === 'cic') {
            setShowCustomCredits(true);
            setSelectedCredits(DEFAULT_CREDITS.defaultCustom);
            setCustomCredits(500);
        }
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let timer;

        if (customCredits && showCustomCredits) {
            timer = setTimeout(async () => {
                await retrieveCustomCredits(signal);
            }, 800);
        }

        return () => {
            controller.abort();
            clearTimeout(timer);
        };
    }, [customCredits]);

    const handleAutorenewCheckbox = (value) => {
        setAutorenewChecked(value);
        if (!value) {
            GTM.pushGTMData({
                event: 'unchecked_credit_auto_renewal',
                environment_type: 'premium',
                content_group: getContentGroup({
                    pageType: getPageType({ pathname }),
                }),
            });
        }
    };

    useEffect(() => {
        const goToCheckoutPage = () => {
            const creditsPrice =
                selectedCredits === DEFAULT_CREDITS.defaultCustom
                    ? customCreditsDetails?.credits?.price?.rounded_price || 0
                    : creditPackOptions.filter((o) => o.credits === selectedCredits)[0]?.price
                          ?.rounded_price || 0;

            GTM.pushGTMData({
                event: 'ecommerce_detail',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name: `${
                                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                                        ? 'Custom'
                                        : selectedCredits
                                } Download Credits`,
                                id: `${
                                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                                        ? 'Custom'
                                        : selectedCredits
                                }`,
                                price: creditsPrice,
                                currency: locale.currency.code,
                                brand: 'Online',
                                category: 'Credits',
                                variant:
                                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                                        ? 'Custom Credits'
                                        : '',
                                quantity: 1,
                                metric1:
                                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                                        ? parseInt(customCredits, 10)
                                        : selectedCredits,
                                metric2: 0,
                                metric3: 0,
                            },
                        ],
                    },
                },
            });

            GTM.pushGTMData({
                event: 'BuyNowOnDemandCreditsClicked',
                category: 'PricingPageCTA',
                label: 'Credits',
                action: 'Plan Type',
            });

            if (
                countryOrigin === 'KR' &&
                (stateDefault.paymentMethodKR === PAYMENT_METHOD.xpay ||
                    ((isMobileView || isTabletView) && process.env.KGINICIS_MOBILE === 'OFF'))
            ) {
                const checkoutURL = `${
                    window.location.origin
                }/checkout-v2/xpay/xpay_payment_request.php?pkg=credits&splan=${
                    selectedCredits === DEFAULT_CREDITS.defaultCustom
                        ? customCredits
                        : selectedCredits
                }`;
                return (window.location.href = checkoutURL);
            }

            const checkoutURL = `/${PAGE_FOLDERS.checkout}/?pkg=credits&plan=${
                selectedCredits === DEFAULT_CREDITS.defaultCustom ? customCredits : selectedCredits
            }&po=pricing${
                (utmCampaign && utmCampaign === "2024SpringPromo") 
                    ? `&promocode=SPRING30` 
                    : promoCode 
                        ? `&promocode=${promoCode}` 
                        : ``
            }${
                autorenewChecked 
                    ? `&autorenew=1` : ``
            }`;
            return Router.push(checkoutURL).then(() => window.scrollTo(0, 0));
        };

        if (buyNowClicked !== undefined) {
            goToCheckoutPage();
        }
    }, [buyNowClicked]);

    return (
        <div className="CreditPackV2__wrapper">
            <div className="CreditPackV2__subWrapper">
                <div className="CreditPackV2__planOption">
                  {creditPackOptions.map((cpo) => (
                      <PlanOptionItem
                          key={`custom-credits-btn-${cpo?.credits}`}
                          cardType={PLAN_TYPES.CREDIT}
                          isCustom={cpo?.credits === DEFAULT_CREDITS.defaultCustom}
                          data={cpo}
                          selectedCredits={selectedCredits}
                          defaultCredits={DEFAULT_CREDITS.defaultCustom}
                          customCredits={customCredits}
                          showCustomCredits={showCustomCredits}
                          customCreditsNote={customCreditsNote}
                          handleOptionSelection={handleOptionSelection}
                          processCustomCredits={processCustomCredits}
                          retrieveCustomCredits={retrieveCustomCredits}
                          customCreditsDetails={customCreditsDetails}
                          autorenew={autorenewChecked}
                          isLangKR={isLangKR}
                          {...props}
                          isAllInOnePlanOn
                      />
                  ))}
                </div>
                {countryOrigin !== 'RU' && countryOrigin !== 'KR' && !TAZAPAY_ACCEPTED_COUNTRIES.includes(countryOrigin) && (
                  <div className="CreditPackV2__autorenewCheckbox">
                      <Checkbox
                          id="autorenew-checkbox"
                          label={intl.get('PRODUCTS.MonthlyAutoRenewal')}
                          checked={autorenewChecked}
                          onChange={(e) => handleAutorenewCheckbox(e.target.checked)}
                      />
                      <div className="CreditPackV2__saveWrapper">
                        <div className="CreditPackV2__save">
                            {intl.get('PRODUCTS.SaveRate', {
                                rate: 20,
                            })}
                        </div>
                    </div>
                  </div>
              )}
              <Button
                    id="buy-now-credits"
                    data-testid="buy-now-credits"
                    name="buy-now-credits"
                    label={intl.get('PRODUCTS.ChoosePlan_BuyNow')}
                    color="secondary"
                    variant="modern"
                    className="CreditPackV2__button"
                    onKeyDown={(e) =>
                        e.key === 'Enter' && handlePricingButtonClick(e)
                    }
                    onClick={() => handlePricingButtonClick()}
                />
            </div>

            <CreditPackDetails isAllInOnePlanOn={isAllInOnePlanOn} refCreditPacksCanBuy={refCreditPacksCanBuy} />

            <style jsx>
                {`
                    .CreditPackV2 {
                        &__wrapper {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: ${isMobileView ? '24px' : '32px'};
                            align-self: stretch;
                        }

                        &__subWrapper {
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                          gap: 16px;
                          align-self: stretch;
                        }

                        &__planOption {
                          transition: all 0.1s ease-in;
                          width: 100%;
                          display: flex;
                          flex-direction: column;
                          row-gap: 8px;
                          align-items: flex-start;
                          gap: 8px;
                          align-self: stretch;
                        }

                        &__creditNote {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 0.938rem;
                            margin-top: 4px;
                        }

                        &__autorenewCheckbox {
                          display: flex;
                          align-items: center;
                          gap: 8px;
                          align-self: stretch;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 18px; /* 150% */
                        }

                        &__saveWrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            height: 100%;
                        }

                        &__save {
                            height: fit-content;
                            font-weight: 600;
                            font-size: 10px;
                            margin-left: 8px;
                            color: #ffffff;
                            white-space: nowrap;
                            border-radius: 44px;
                            background: #e91d35;
                            padding: 0px 8px 0px 9px;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                `}
            </style>

            <style jsx global>
                {`
                    .CreditPackV2 {
                        &__button {
                            display: flex;
                            height: 44px;
                            width: 100%;
                            padding: 8px 45px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            align-self: stretch;
                            border-radius: 38px;
                            border: 1px solid var(--Greyscale-G25, #A3A3A3);
                            background: var(--Greyscale-White, #FFF);
                        }
                    }
                `}
            </style>
        </div>
    );
};

CreditPackV2.propTypes = {
    isAllInOnePlanOn: PropTypes.bool,
    products: PropTypes.objectOf(PropTypes.shape),
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    GTM: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    refCreditPacksCanBuy: PropTypes.objectOf(PropTypes.shape).isRequired,
};

CreditPackV2.defaultProps = {
    products: null,
    isAllInOnePlanOn: false,
};

export default CreditPackV2;

/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from "classnames";
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Sub Component
import PlanDetailsItem from 'components/Products/NewAffordablePlans/Shared/PlanDetailsItem';
import AccessToAISuite from 'components/Products/NewAffordablePlans/Shared/AccessToAISuite';

const CreditPackDetails = ({ refCreditPacksCanBuy, isAllInOnePlanOn }) => {
    const UIAccessToAISuite = (
        <AccessToAISuite 
            isFullAccess
        />
    );

    const WhatCanIGetWithCredits = () => (
        <a
            className="TextLink TextLink--primary CreditPackDetails__link"
            role="button"
            tabIndex="0"
            onClick={() =>
                refCreditPacksCanBuy.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
            onKeyDown={(e) =>
                e.key === 'Enter' &&
                refCreditPacksCanBuy.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
        >
            {intl.getHTML('PRODUCTS.CreditWhatCanIGet')}
        </a>
    );
    return (
        <ul className={cx("CreditPackDetails__wrapper", 
            isAllInOnePlanOn && "CreditPackDetails__bullet--small"
        )}>
            {isAllInOnePlanOn ? (<>
                <PlanDetailsItem item={UIAccessToAISuite} itemClass="CreditPackDetails__list--small"/>
                {/* from UI Core - Access 220M images: */}
                <PlanDetailsItem item={intl.getHTML('FOOTER_PLUS_UPSELL_DESC2')} itemClass="CreditPackDetails__list--small" />
            </>)
            : (<>
                <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditDownloadStocks')} itemClass="CreditPackDetails__list--small" />
                <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditPremiumCollection')} itemClass="CreditPackDetails__list--small" />
                <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditDownloadWhatAndWhen')} itemClass="CreditPackDetails__list--small"/>
            </>)}
            <PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditStandardExtendedLicenses')} type="link" itemClass="CreditPackDetails__list--small"/>
            {!isAllInOnePlanOn && (<><PlanDetailsItem item={intl.getHTML('PRODUCTS.CreditMixMatch')} itemClass="CreditPackDetails__list--small"/>
            <PlanDetailsItem item={WhatCanIGetWithCredits()} type="link" itemClass="CreditPackDetails__list--small"/></>)}

            <style jsx>
                {`
                    .CreditPackDetails {
                        &__wrapper {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            margin-top: ${isAllInOnePlanOn ? 'unset' : '16px'};
                        }
                    }
                `}
            </style>

            <style jsx global>
                {`
                    .CreditPackDetails {
                        &__bullet--small {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            align-self: stretch;
                            margin-bottom: unset !important;
                            padding-left: 24px !important;
                            gap: 8px !important;

                            > ul {
                                list-style: none;
                                padding-inline-start: 0px;
                                margin-bottom: 0rem;
                            }

                            > li {
                                display: flex;
                                align-items: center;
                                margin-left: 0;
                                padding-left: 0;
                                position: relative;
                                list-style-position: inside;

                                &::before {
                                    content: '•';
                                    position: absolute;
                                    left: -13px;
                                    top: 0;
                                }
                            }
                        }
    
                        &__list--small {
                            min-height: 18px !important;
                            color: var(--Greyscale-G10, #212121);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400 !important;
                            line-height: 18px !important; /* 150% */
                        }
                    }
                `}
            </style>
        </ul>
    );
};

CreditPackDetails.propTypes = {
    isAllInOnePlanOn: PropTypes.bool,
    refCreditPacksCanBuy: PropTypes.objectOf(PropTypes.shape).isRequired,
};

CreditPackDetails.defaultProps = {
    isAllInOnePlanOn: false,
};

export default CreditPackDetails;

import { useState, useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { getActivePlusPlan } from '123rf-ui-core/queries';
import { useEventPromoStatus, useEventList } from '123rf-ui-core/utils';
import { useHeightContext } from 'contexts/PricingPlanHeight';
import { PlusPricingCard } from '123rf-ui-core/components';

// Pre-defined Components
import {
    PLAN_DURATION,
    PLAN_TYPE_PLUS,
    PLANS_WITH_PROMOCODE_PRICING,
    COUNTRIES_WITHOUT_AUTORENEWAL,
    COUNTRIES_WITHOUT_PLUS_FREE_TRIAL,
} from 'config/products';
import { PAGE_FOLDERS, DEFAULT_STATE_TEXT_VALUE as stateDefault } from 'config/constants';
import {
    PAYMENT_METHOD,
    PRODUCT_PACKAGE,
    PLUS_PLANS,
    // PLUS_WITHOUT_ADYEN_COUNTRIES // activate it when needed to show weekly to filter it from certain countries
} from 'config/payments';

const PlusAllInOnePlan = (props) => {
    const eventList = useEventList();

    const {
        products,
        showPlusFreeTrial,
        setIsPlusFreeTrialSelected,
        CommonDetails,
        GTM,
        WindowDimension,
        User,
    } = props;

    const {
        aioPlanHeight,
        plusAIOPlanRef,
        setAioPlanHeight,
        setEnterpriseHeaderHeight,
        aioHeaderHeightRef,
    } = useHeightContext();
    const { locale, countryOrigin: co, promoCode, utmCampaign } = CommonDetails;
    const { isTabletView, isMobileView, width: windowWidth } = WindowDimension;
    const { userIsLoggedIn } = User;
    const countryOrigin = locale?.country?.code || co;
    const { isEventPromoRunning } = useEventPromoStatus(countryOrigin);
    const hasNoPlusFreeTrial =
        COUNTRIES_WITHOUT_AUTORENEWAL.includes(countryOrigin) ||
        COUNTRIES_WITHOUT_PLUS_FREE_TRIAL.includes(countryOrigin);
    const DEFAULT_SELECTED_PLAN = PLUS_PLANS.plusYearlySpecial;
    const isPromoNEW50 = promoCode === 'NEW50';

    const planTypes = {
        fplus7: intl.get('SEVEN_FREE_TRIAL'),
        plusw1: intl.get('WEEKLY'),
        plus1: intl.get('MONTHLY'),
        splus12: intl.get('YEARLY'),
        'i-splus12': intl.get('YEARLY_PAID_MONTHLY'),
    };

    const [isPricingCardButtonClicked, setIsPricingCardButtonClicked] = useState(false);
    const [isResetingCardHeight, setIsResetingCardHeight] = useState(false);
    const [isWeeklyPlusActive, setIsWeeklyPlusActive] = useState(false);
    const [isYearlyPlusInstallmentActive, setIsYearlyPlusInstallmentActive] = useState(false);

    const getFilteredPlans = useMemo(() => {
        return ({
            allPlans,
            country,
            showFreeTrial,
            isWeeklyActive,
            isYearlyInstallmentActive,
        }) => {
            if (!allPlans) return [];

            // Define filter conditions
            const isFreeTrialBlocked =
                COUNTRIES_WITHOUT_AUTORENEWAL.includes(country) ||
                COUNTRIES_WITHOUT_PLUS_FREE_TRIAL.includes(country);

            return allPlans.filter((prod) => {
                // First check if plan type is allowed based on country and active settings
                const isPlanAllowed = (() => {
                    // Weekly plan filter
                    if (prod.plan === PLUS_PLANS.plusWeekly && !isWeeklyActive) {
                        return false;
                    }

                    // Free trial filter - check country restrictions and showFreeTrial flag
                    if (prod.plan === PLUS_PLANS.plusFreeTrial) {
                        if (isFreeTrialBlocked) return false;
                        if (!showFreeTrial) return false;
                    }

                    // Yearly installment filter
                    if (
                        prod.plan === PLUS_PLANS.plusYearlyInstallment &&
                        !isYearlyInstallmentActive
                    ) {
                        return false;
                    }

                    // Plan is allowed if it passed all filters
                    return true;
                })();

                return isPlanAllowed && PLAN_TYPE_PLUS.includes(prod.plan);
            });
        };
    }, []);

    const filteredPlans = useMemo(
        () =>
            getFilteredPlans({
                allPlans: products?.subs,
                country: countryOrigin,
                showFreeTrial: showPlusFreeTrial,
                isWeeklyActive: isWeeklyPlusActive,
                isYearlyInstallmentActive: isYearlyPlusInstallmentActive,
            }),
        [
            products?.subs,
            countryOrigin,
            showPlusFreeTrial,
            isWeeklyPlusActive,
            isYearlyPlusInstallmentActive,
            getFilteredPlans,
        ],
    );

    const [selectedPlan, setSelectedPlan] = useState(DEFAULT_SELECTED_PLAN);
    const [selectedPlanContent, setSelectedPlanContent] = useState({});
    const [plusPlanOptions, setPlusPlanOptions] = useState(filteredPlans);

    const determinePlanIndex = (plan) => {
        const index =
            plusPlanOptions.findIndex((planOption) => planOption.plan === plan) || undefined;
        return index;
    };

    const [defaultPlanIndex, setDefaultPlanIndex] = useState(undefined);
    const [cardsContent, setCardsContent] = useState({});

    const isPlusPlanActive = useMemo(() => {
        if (userIsLoggedIn) {
            const {
                plus = false,
                plusb = false,
                plusteams = false,
            } = User?.userDetails?.personal_details?.active_plans || {};
            return plus || plusb || plusteams;
        }
        return false;
    }, [userIsLoggedIn, User?.userDetails?.personal_details?.active_plans]);

    useEffect(() => {
        const checkActivePlusPlan = async () => {
            if (isPlusPlanActive) {
                const data = await getActivePlusPlan();
                const activePlan = data?.active_plus_plan;

                // Set active plan state
                if (activePlan === PLUS_PLANS.plusWeekly) {
                    setIsWeeklyPlusActive(true);
                } else if (activePlan === PLUS_PLANS.plusYearlyInstallment) {
                    setIsYearlyPlusInstallmentActive(true);
                }

                setSelectedPlan(activePlan || DEFAULT_SELECTED_PLAN);
            }
        };

        checkActivePlusPlan();
    }, [isPlusPlanActive]);

    const isPromoStatusShifted = (planSelected) =>
        (!selectedPlanContent.has_promo_price && planSelected.has_promo_price) ||
        (selectedPlanContent.has_promo_price && !planSelected.has_promo_price);

    const handleOptionSelection = (planSelected) => {
        document.body.style.overflow = 'auto';
        const selected = planSelected.value;

        if (isPromoNEW50 && isPromoStatusShifted(planSelected)) {
            setIsResetingCardHeight(true);
        }

        if (selectedPlan !== selected) {
            setSelectedPlan(selected);
            setSelectedPlanContent(planSelected);
            setIsPlusFreeTrialSelected(selected === PLAN_TYPE_PLUS[1]); // free trial - fplus7

            const planduration =
                selected.indexOf('12') >= 0 ? PLAN_DURATION.yearly : PLAN_DURATION.monthly;
            const planVariant = `${(selected.indexOf('12') >= 0
                ? selected.substring(0, selected.length - 2)
                : selected.substring(0, selected.length - 1)
            ).replace(/^\w/, (c) => c.toUpperCase())}`;
            const planPrice =
                plusPlanOptions.filter((o) => o?.plan === selected)[0]?.price?.rounded_price || 0;
            const planType = selected.replace(/\d+/, '');
            const planTypeDesc =
                planType === 'basic' ? 'Daily Subscription' : 'Monthly Subscription';

            GTM.pushGTMData({
                event: 'ecommerce_detail',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name: `${planTypeDesc} — ${planduration} ${
                                    planduration > 1 ? 'Months' : 'Month'
                                }`,
                                id: selected,
                                price: planPrice,
                                currency: locale.currency.code,
                                brand: 'Online',
                                category: 'Subscription',
                                variant: `${planVariant} Subscription`,
                                quantity: 1,
                                metric1: 0,
                                metric2: 0,
                                metric3: planduration,
                            },
                        ],
                    },
                },
            });
        }
    };

    const handleCheckout = (planSelected) => {
        const selected = planSelected.value;
        if (selectedPlan !== selected) {
            setSelectedPlan(selected);
        }

        setIsPricingCardButtonClicked((prev) => !prev);
    };

    const eventPromocode = () => {
        const { eventPromo } = eventList;
        const useUtmCampaign = utmCampaign === eventPromo.utmCampaign;

        const isEventPromoValid =
            isEventPromoRunning && useUtmCampaign && selectedPlan === PLAN_TYPE_PLUS[0];
        const promocode = isEventPromoValid ? eventPromo.promocode : '';

        return promocode;
    };

    useEffect(() => {
        if (plusPlanOptions) {
            const planOptions = plusPlanOptions.map((plan) => {
                const {
                    plan: planType,
                    promo_price_per_month: promoPricePerMonth,
                    promo_price: promoPrice,
                    price_per_month: pricePerMonth,
                    price,
                } = plan;
                const isYearlyPlan = planType === 'splus12' || planType === 'i-splus12';

                return {
                    label: planTypes[planType],
                    pkg: PRODUCT_PACKAGE.subs,
                    value: planType,
                    promo_price: (isYearlyPlan ? promoPricePerMonth : promoPrice)?.formatted_price,
                    price: (isYearlyPlan ? pricePerMonth : price)?.formatted_price,
                    type: planType,
                    subtitle: planType === 'fplus7' ? intl.get('CANCEL_ANYTIME') : '',
                };
            });

            setDefaultPlanIndex(determinePlanIndex(selectedPlan));
            setSelectedPlanContent(planOptions.find((plan) => plan.value === selectedPlan) || {});

            setCardsContent([
                {
                    pkg: PRODUCT_PACKAGE.subs,
                    title: intl.get('PLUS'),
                    planOptions: planOptions,
                    promoPrice: planOptions[0]?.promo_price,
                    totalPrice: planOptions[0]?.price,
                    footerTitle: intl.getHTML('FOOTER_PLUS_UPSELL_TITLE'),
                    footer: [
                        { id: 'footer1', text: intl.getHTML('FOOTER_PLUS_UPSELL_DESC1') },
                        { id: 'footer2', text: intl.getHTML('FOOTER_PLUS_UPSELL_DESC2') },
                        { id: 'footer3', text: intl.getHTML('FOOTER_PLUS_UPSELL_DESC3') },
                        { id: 'footer4', text: intl.getHTML('FOOTER_PLUS_UPSELL_DESC4') },
                    ],
                    header: intl.getHTML('SUBSCRIPTIONS'),
                },
            ]);
        }
    }, [plusPlanOptions]);

    useEffect(() => {
        if (!hasNoPlusFreeTrial) {
            setPlusPlanOptions(filteredPlans);
            setSelectedPlan(isPlusPlanActive ? selectedPlan : DEFAULT_SELECTED_PLAN);
            setIsPlusFreeTrialSelected(showPlusFreeTrial && selectedPlan === PLAN_TYPE_PLUS[1]);
        }
    }, [showPlusFreeTrial, isPlusPlanActive, filteredPlans, hasNoPlusFreeTrial, selectedPlan]);

    useEffect(() => {
        if (isPricingCardButtonClicked) {
            const goToCheckoutPage = () => {
                const planduration =
                    selectedPlan.indexOf('12') >= 0 ? PLAN_DURATION.yearly : PLAN_DURATION.monthly;
                const planVariant = `${(selectedPlan.indexOf('12') >= 0
                    ? selectedPlan.substring(0, selectedPlan.length - 2)
                    : selectedPlan.substring(0, selectedPlan.length - 1)
                ).replace(/^\w/, (c) => c.toUpperCase())}`;
                const planPrice =
                    plusPlanOptions.filter((o) => o?.plan === selectedPlan)[0]?.price
                        ?.rounded_price || 0;
                const planType = selectedPlan.replace(/\d+/, '');
                const planTypeDesc =
                    planType === 'basic' ? 'Daily Subscription' : 'Monthly Subscription';

                const eventPromoCode = eventPromocode() || promoCode;

                GTM.pushGTMData({
                    event: 'ecommerce_detail',
                    ecommerce: {
                        detail: {
                            products: [
                                {
                                    name: `${planTypeDesc} — ${planduration} ${
                                        planduration > 1 ? 'Months' : 'Month'
                                    }`,
                                    id: selectedPlan,
                                    price: planPrice,
                                    currency: locale.currency.code,
                                    brand: 'Online',
                                    category: 'Subscription',
                                    variant: `${planVariant} Subscription`,
                                    quantity: 1,
                                    metric1: 0,
                                    metric2: 0,
                                    metric3: planduration,
                                },
                            ],
                        },
                    },
                });

                GTM.pushGTMData({
                    event: 'BuyNowSubscriptionPlanClicked',
                    category: 'PricingPageCTA',
                    label: 'Subscription',
                    action: 'Plan Type',
                });

                const matchedPlan =
                    PLANS_WITH_PROMOCODE_PRICING.find((plan) => plan.plan === selectedPlan) || '';

                if (
                    countryOrigin === 'KR' &&
                    (stateDefault.paymentMethodKR === PAYMENT_METHOD.xpay ||
                        ((isMobileView || isTabletView) && process.env.KGINICIS_MOBILE === 'OFF'))
                ) {
                    const checkoutURL = `${
                        window.location.origin
                    }/checkout-v2/xpay/xpay_payment_request.php?pkg=subs&splan=${selectedPlan}${
                        eventPromoCode
                            ? `&promocode=${eventPromoCode}`
                            : matchedPlan && `&promocode=${matchedPlan.promocode}`
                    }`;
                    window.location.href = checkoutURL;
                } else {
                    const checkoutURL = `/${
                        PAGE_FOLDERS.checkout
                    }/?pkg=subs&plan=${selectedPlan}&po=pricing${
                        selectedPlan === 'fplus7'
                            ? `&promocode=FREEPLUS`
                            : eventPromoCode
                              ? `&promocode=${eventPromoCode}`
                              : matchedPlan && `&promocode=${matchedPlan.promocode}`
                    }`;
                    Router.push(checkoutURL).then(() => window.scrollTo(0, 0));
                }
            };

            const goToContactPage = () => {
                window.open('/contact/', '_blank');
            };

            if (isPlusPlanActive) {
                goToContactPage();
                setIsPricingCardButtonClicked(undefined);
            } else {
                goToCheckoutPage();
            }
        }
    }, [isPricingCardButtonClicked]);

    useEffect(() => {
        if (isResetingCardHeight) {
            setAioPlanHeight(null);
            setEnterpriseHeaderHeight(null);
            setIsResetingCardHeight(false);
        }
    }, [isResetingCardHeight]);

    return (
        <section className="PricingCard__wrapper" ref={plusAIOPlanRef}>
            <>
                {cardsContent && cardsContent.length && (
                    <>
                        {cardsContent &&
                            cardsContent.length &&
                            cardsContent.map((content) => (
                                <PlusPricingCard
                                    defaultPlanIndex={defaultPlanIndex}
                                    content={content}
                                    key={content?.pkg}
                                    size={windowWidth < 768 ? 'sm' : 'lg'}
                                    onSelection={handleOptionSelection}
                                    onCheckout={handleCheckout}
                                    countryCode={countryOrigin}
                                    className="PricingCard__plusWrapper"
                                    promoCode={promoCode}
                                    headerRef={aioHeaderHeightRef || undefined}
                                    isPriceListAreaShort={
                                        isMobileView || (selectedPlan !== 'fplus7' && isPromoNEW50)
                                    }
                                />
                            ))}
                    </>
                )}
            </>
            <style jsx global>
                {`
                    .PricingCard {
                        &__wrapper {
                            min-height: auto;
                            width: 100%;

                            // @include breakpoint('md') {
                            @media (min-width: 768px) {
                                height: ${aioPlanHeight || '100%'};
                                width: unset;
                            }
                        }

                        &__plusWrapper {
                            width: 100% !important;
                            height: 100% !important;

                            // @include breakpoint('md') {
                            @media (min-width: 768px) {
                                width: 340px !important;
                            }

                            // @include breakpoint('lg') {
                            @media (min-width: 1024px) {
                                width: 362px !important;
                            }

                            .Modalv2__container {
                                z-index: 9999;
                            }
                        }
                    }
                `}
            </style>
        </section>
    );
};

PlusAllInOnePlan.propTypes = {
    products: PropTypes.objectOf(PropTypes.shape),
    showPlusFreeTrial: PropTypes.bool,
    setIsPlusFreeTrialSelected: PropTypes.func.isRequired,
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    GTM: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    User: PropTypes.objectOf(PropTypes.shape).isRequired,
};

PlusAllInOnePlan.defaultProps = {
    products: null,
    showPlusFreeTrial: false,
};

export default PlusAllInOnePlan;

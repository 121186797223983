import PropTypes from 'prop-types';
import { Button } from '123rf-ui-core/components';
import intl from 'react-intl-universal';
import { useHeightContext } from 'contexts/PricingPlanHeight';

// Pre-defined Components
import { PRODUCTS_IMAGES as images } from 'config/images/products';

// Sub Components
import PlusEnterprisePlanDetails from './PlusEnterprisePlanDetails';

const PlusEnterprise = (props) => {
    const {
        onTalkToUs,
    } = props;

    const { enterprisePlanRef, aioPlanHeight, enterpriseHeaderHeight } = useHeightContext();

    return (
        <section className="PlusEnterprisePlan__wrapper" ref={enterprisePlanRef}>
            <div className="PlusEnterprisePlan__subWrapper">
                <div className="PlusEnterprisePlan__header">
                    <div className="PlusEnterprisePlan__planName">
                        {intl.get('PRODUCTS.PlusEnterprise_title')}
                    </div>
                    <div className="PlusEnterprisePlan__subheader">
                        <img
                            className="TableCell__icon"
                            src={images.plusEnterpriseIcon}
                            height="48px"
                            width="48px"
                            alt="plus-enterprise-icon"
                            loading="lazy"
                        />
                        <div className="PlusEnterprisePlan__planDescription">
                            {intl.get('PRODUCTS.PlusEnterprise_TailoredSolution')}
                        </div>
                    </div>
                </div>
                
                <Button
                    id="talk-to-us-pluse"
                    data-testid="talk-to-us-pluse"
                    name="talk-to-us-pluse"
                    label={intl.get('PRODUCTS.TalkToUs')}
                    variant="modern"
                    color="secondary"
                    onClick={onTalkToUs}
                    onKeyDown={(e) =>
                        e.key === 'Enter' && onTalkToUs(e)
                    }
                    className="PlusEnterprisePlan__button"
                />
                
                <div>
                    <PlusEnterprisePlanDetails {...props} />
                </div>
            </div>

            <style jsx>
                {`
                    .PlusEnterprisePlan {
                        &__wrapper {
                            flex-basis: auto;
                            position: relative;
                            width: 100%;
                            min-height: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: start;
                            align-items: flex-start;
                            padding: 16px;
                            z-index: 1;
                            background: transparent;
                            border-radius: 8px;
                            overflow: hidden;
                            order: 2;
                            background: #ffffff;

                            // @include breakpoint('md') {
                            @media (min-width: 768px) {
                                height: ${aioPlanHeight || '100%'};
                                width: 340px;
                                padding: 24px;
                            }

                            // @include breakpoint('lg') {
                            @media (min-width: 1024px) {
                                width: 362px;
                            }
                        }

                        &__subWrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: var(--16px, 16px);
                            align-self: stretch;
                        }

                        &__header {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 8px;
                            align-self: stretch;

                            // @include breakpoint('md') {
                            @media (min-width: 768px) {
                                height: ${enterpriseHeaderHeight || '122px'};
                            }
                        }

                        &__planName {
                            display: flex;
                            align-items: center;
                            gap: 42px;
                            align-self: stretch;
                            color: var(--Greyscale-G10, #212121);
                            text-align: center;

                            /* Desktop/Heading/H2 Semibold */
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 36px; /* 150% */
                        }

                        &__subheader {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }

                        &__planDescription {
                            color: var(--Greyscale-G10, #212121);
                            text-align: left;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px; /* 150% */
                        }
                    }
                `}
            </style>

            <style jsx global>
                {`
                    .PlusEnterprisePlan {
                        &__button {
                            display: flex;
                            height: 44px;
                            width: 100%;
                            padding: 8px 45px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            align-self: stretch;
                            border-radius: 38px;
                            border: 1px solid var(--Greyscale-G25, #A3A3A3);
                            background: var(--Greyscale-White, #FFF);
                        }
                    }
                `}
            </style>
        </section>
    );
};

PlusEnterprise.propTypes = {
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    onTalkToUs: PropTypes.func.isRequired,
};

export default PlusEnterprise;

import cx from "classnames";
import PropTypes from 'prop-types';

const PlanDetailsItem = ({ item, itemClass }) => (
    <li className="PlanDetails__list">
        <span
            className={cx("PlanDetails__listItem", itemClass)}
        >
            {item}
        </span>

        <style jsx>
            {`
                .PlanDetails {
                    &__list {
                        margin-left: -10px;
                    }

                    &__tickIcon {
                        height: 24px;
                        width: 25px;
                    }

                    &__listItem {
                        min-height: 24px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 1.125rem;
                        color: #212121;
                    }
                }
            `}
        </style>
        <style jsx global>
            {`
                .PlanDetails {
                    &__link {
                        a {
                            color: #2298ca !important;
                            text-decoration-line: underline !important;
                            text-underline-offset: 4px;
                        }
                    }
                }
            `}
        </style>
    </li>
);

PlanDetailsItem.propTypes = {
    item: PropTypes.node.isRequired,
    itemClass: PropTypes.string,
};

PlanDetailsItem.defaultProps = {
    itemClass: '',
};

export default PlanDetailsItem;

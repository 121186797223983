import { memo } from 'react';
import dynamic from 'next/dynamic';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Pre-defined Components
import getPopularBrands from './getPopularBrands';

// Loadable Components
const BrandLogosInMobile = dynamic(() => import('./BrandLogosInMobile'));
const BrandLogosInTablet = dynamic(() => import('./BrandLogosInTablet'));
const BrandLogosInDesktop = dynamic(() => import('./BrandLogosInDesktop'));

function TrustedByMillionsWorldwide(props) {
    const { isDesktopView, isTabletView, isTabletLGView, isMobileView, isAllInOnePlanOn } = props;
    const popularBrands = getPopularBrands(isTabletView);

    return (
        <section className="TrustedWorldwide__wrapper">
            <h2 className="TrustedWorldwide__title">
                {intl.get('PRODUCTS.TrustedWorldwide_Title')}
            </h2>
            <div className="TrustedWorldwide__subWrapper">
                {isDesktopView && <BrandLogosInDesktop popularBrands={popularBrands} />}
                {isMobileView && <BrandLogosInMobile popularBrands={popularBrands} />}
                {isTabletView && <BrandLogosInTablet popularBrands={popularBrands} />}
            </div>

            <style jsx>
                {`
                    .TrustedWorldwide {
                        &__wrapper {
                            min-height: 246px;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: ${isDesktopView || isTabletLGView ? '60px 0' : isTabletView ? '24px 0' : '24px 16px'};
                            background-color: #ffffff;
                            gap: ${isDesktopView ? '40px' : isTabletView ? '24px' : '16px'};
                        }

                        &__title {
                            width: ${isMobileView && !isAllInOnePlanOn ? '300px' : '100%'};
                            font-size: ${isMobileView ? '18px' : '24px'};
                            font-weight: 600;
                            letter-spacing: 0;
                            line-height: ${isMobileView ? '1.5rem' : '2.5rem'};
                            color: #212121;
                            text-align: center;
                        }

                        &__subWrapper {
                            position: relative;
                            display: block;
                            align-items: unset;
                            justify-content: unset;
                            width: 100%;
                            max-width: ${isDesktopView || isTabletLGView
                                ? 'initial'
                                : isTabletView
                                ? 'calc(100% - 90px)'
                                : 'unset'};
                        }
                    }
                `}
            </style>
        </section>
    );
}

TrustedByMillionsWorldwide.propTypes = {
    isAllInOnePlanOn: PropTypes.bool,
    isTabletLGView: PropTypes.bool.isRequired,
    isDesktopView: PropTypes.bool.isRequired,
    isTabletView: PropTypes.bool.isRequired,
    isMobileView: PropTypes.bool.isRequired,
};

TrustedByMillionsWorldwide.defaultProps = {
    isAllInOnePlanOn: false,
}

export default memo(TrustedByMillionsWorldwide);

import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

// Footer Components
const UIMainFooter = dynamic(() => import('./UIMainFooter'));
const UISimpleFooter = dynamic(() => import('./UISimpleFooter'));

function PageFooter({ footerType, locale, onChangeLang, isAllInOnePlanOn }) {
    if (footerType === 'main') {
        return <UIMainFooter localeData={locale} changeLanguageHandler={onChangeLang} />;
    }

    return (
        <>
            <UISimpleFooter
                phoneNumber={
                    locale?.country?.code === 'CN' ? [''] : locale?.contactUs?.phoneNumber || ['']
                }
                email={locale?.contactUs?.email || ''}
            />

            <style jsx global>
                {`
                    .SimpleFooter
                        > .SimpleFooter__wrapper {
                            border-top: ${ isAllInOnePlanOn ? '0px' : '1px solid var(--whisper)'} 
                        }
                        > .SimpleFooter__wrapper
                        > .SimpleFooter__itemContainer
                        > .SimpleFooter__separator {
                        display: ${locale?.country?.code === 'CN'
                            ? 'none'
                            : 'inline-block'} !important;
                    }
                `}
            </style>
        </>
    );
}

PageFooter.propTypes = {
    footerType: PropTypes.string,
    locale: PropTypes.objectOf(PropTypes.shape).isRequired,
    onChangeLang: PropTypes.func,
    isAllInOnePlanOn: PropTypes.bool,
};

PageFooter.defaultProps = {
    footerType: 'simple',
    onChangeLang: () => null,
    isAllInOnePlanOn: false,
};

export default PageFooter;

import cx from 'classnames';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Pre-defined Components
import { DEFAULT_CREDITS } from 'config/products';

const CreditsTextInput = (props) => {
    const { data, selectedCredits, customCredits, processCustomCredits, isAllInOnePlanOn } = props;

    const preventNonNumericalInput = (event) => {
        const e = event || window.event;
        const charCode = e.which || e.keyCode;

        // Allow backspace (8) and delete (46)
        if (charCode === 8) {
            return;
        }

        if (charCode === 46) {
            e.preventDefault();
            return;
        }

        // Handle Android key code 229 for virtual keyboards
        if (charCode === 229) {
            const input = e.target.value;
            if (!input.match(/^[0-9]*$/)) {
                e.preventDefault();
            }
            return;
        }

        // Allow number keys (0-9) on both main keyboard and numpad
        const isNumpadKey = (charCode >= 96 && charCode <= 105);
        const isMainNumberKey = (charCode >= 48 && charCode <= 57);

        if (!isNumpadKey && !isMainNumberKey) {
            e.preventDefault();
        }
    };

    const DEFAULT_CREDITS_MIN_CUSTOM = isAllInOnePlanOn ? DEFAULT_CREDITS.minCustomAIO : DEFAULT_CREDITS.minCustom;

    return (
        <div className="CreditsTextInput__wrapper">
            <input
                id="custom-credits"
                data-testid="custom-credits"
                type="number"
                value={customCredits}
                onChange={processCustomCredits}
                min={DEFAULT_CREDITS_MIN_CUSTOM}
                max={DEFAULT_CREDITS.maxCustom}
                inputMode="numeric"
                onKeyDown={preventNonNumericalInput}
                className={cx(
                    'CreditsTextInput__input',
                    data?.credits === selectedCredits && 'CreditsTextInput__input--selected',
                )}
                aria-label={intl.get('SHARED.Credits')}
                /* eslint-disable-next-line */
                autoFocus
            />

            <style jsx>
                {`
                    .CreditsTextInput {
                        &__wrapper {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                        }

                        &__input {
                            width: 50px;
                            height: 19px;
                            letter-spacing: 0px;
                            outline: none;
                            background: #ffffff;
                            border: 1px solid #e0e0e0;
                            border-radius: 4px;
                            font-weight: 400;
                            line-height: 1.063rem;
                            text-align: center;
                            color: #212121;

                            /* Chrome, Safari, Edge, Opera */
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }

                            /* Firefox */
                            &[type='number'] {
                                -moz-appearance: textfield;
                            }
                        }

                        &__input--selected,
                        &__input--selected:hover {
                            border: 1px solid #e0e0e0;
                            color: #333333;
                        }

                        &__input--error {
                            border: 1px solid #e91d36;
                        }
                    }
                `}
            </style>
        </div>
    );
};

CreditsTextInput.propTypes = {
    data: PropTypes.objectOf(PropTypes.shape).isRequired,
    selectedCredits: PropTypes.number.isRequired,
    customCredits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    processCustomCredits: PropTypes.func.isRequired,
    isAllInOnePlanOn: PropTypes.bool,
};

CreditsTextInput.defaultProps = {
    isAllInOnePlanOn: false,
};

export default CreditsTextInput;
